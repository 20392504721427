import { Box, Button, Flex, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, Tooltip, useSteps } from "@chakra-ui/react";
import CarBoxInfo from "../../components/CardBox/CarBoxInfo";
import { FaCashRegister, FaCheck, FaCheckDouble, FaCode, FaDollarSign, FaDollyFlatbed, FaHistory, FaListAlt, FaQrcode, FaRegTimesCircle, FaReplyAll, FaRocket, FaRocketchat } from "react-icons/fa";
import { CardBox, CardBoxTitulo } from "../../components/CardBox/CardBox";
import { GM, saldos } from "../../conn/WebPainel";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../components/ListaResponsiva/ListaResponsiva"; 
const PagePacotes = function(){
    const [alerta,setAlerta] = useState({});   
    useEffect(()=>{},[alerta]); 
    const [reload,setReload]= useState(0);
    
    const [load,setLoad] = useState(0);
    const [sendD,setSendD] = useState({
        valor:'',
        forma:'',
    })
    useEffect(()=>{ },[load])
    const titulos = [
        {nome:'ID',         w:2,    campo:'id'}, 
        {nome:'DESCRIÇÃO',     w:10,    campo:'nome'},  
        {nome:'VALOR',      w:3,    campo:'valor'},  
        {nome:'DATA',       w:5,    campo:'criacao'} 
    ]; 
    let dados = null;  
    useEffect(()=>{    
        var interV = setInterval(()=>{
            try { 
                if(reload!=GM.lancamentos.up){ 
                    setReload(GM.lancamentos.up); 
                    setAlerta({close:true})
                }  
            } catch (error) { } 
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]); 
    console.log(GM); 
    if(typeof GM.pacotes !== 'undefined'){
        dados   = []; 
        Object.values(GM.pacotes.dados).forEach((d)=>{
            
            const {id,codtip,valor,pago,criacao}=d;  
            const tipo = GM.lancamentosTipo.dados[codtip].nome 
            dados.push({
                campos  :   d,
                id      :   <Text  noOfLines={'1'} py={'5px'} >{id}</Text>,  
                nome    :   <Text  noOfLines={'1'} py={'5px'} >{tipo}</Text>, 
                valor    :   <Text  noOfLines={'1'} py={'5px'} >{valor}</Text>,  
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
                
            });
        })
    }  
    return (
        <Stack gap={'20px'}>
             <SimpleGrid    columns={{base:2,'lg':4}} spacing={'20px'}>
                <CarBoxInfo titulo='Depósitos'      sub='Totais'    valor={saldos.depositos} icon={FaDollarSign}/>
                <CarBoxInfo titulo='Saques'         sub='Totais'    valor={saldos.saque} icon={FaCheckDouble}/>
                <CarBoxInfo titulo='Rendimentos'    sub='Totais'    valor={saldos.rendimento} icon={FaCheck}/>
                <CarBoxInfo titulo='Rede'           sub='Total'     valor={saldos.indicacao+saldos.indicacaoI+saldos.residual} icon={FaHistory}/>
            </SimpleGrid>  
            <CardBox w={{base:'100%'}}>
                <CardBoxTitulo position='absolute'>Pacotes Ativos</CardBoxTitulo>
                <ListaResponsiva titulos={titulos} dados={dados} max={20} cmd={[]} />
            </CardBox> 
        </Stack>
    )
};
export default PagePacotes;