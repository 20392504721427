import { Box, Button, Flex, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, Tooltip, useSteps } from "@chakra-ui/react";
import CarBoxInfo from "../../components/CardBox/CarBoxInfo";
import { FaCashRegister, FaCheck, FaCheckDouble, FaCode, FaDollarSign, FaDollyFlatbed, FaHistory, FaListAlt, FaQrcode, FaRegTimesCircle, FaReplyAll, FaRocket, FaRocketchat, FaUsers, FaUsersSlash } from "react-icons/fa";
import { CardBox, CardBoxTitulo } from "../../components/CardBox/CardBox";
import { GM } from "../../conn/WebPainel";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../components/ListaResponsiva/ListaResponsiva"; 
import { TbNumber1 } from "react-icons/tb";
import { LiaNetworkWiredSolid } from "react-icons/lia";

const BoxLevel = function(props){
    const {nivel,setNivel,nN={}} = props;
   
    
    return <SimpleGrid  gap={'10px'} columns={{base:4,md:5,'xl':10}} spacing={'0px'}>
        {[0,1,2,3,4,5,6,7,8,9].map((n)=>{
            return   (
                <Button  key={'nv'+n} onClick={()=>{setNivel(n)}} gap={'5px'} variant={nivel==n?"solid":"outline"} w={'100%'} alignItems={'center'} justifyItems={'center'} fontSize={'14px'}  lineHeight='20px' colorScheme={nivel==n?"cyan":"whiteAlpha"}>
                    <Text>N.{(n+1)}</Text> - {nN[n]>0?<Icon as={FaUsers}  />:<Icon as={FaUsersSlash}  />} <Text> {nN[n]>0?nN[n]:0}</Text>
                </Button>
            ) 
        })}
        
    </SimpleGrid >
}
const PageRede = function(){
    const [alerta,setAlerta] = useState({});   
    useEffect(()=>{},[alerta]); 
    const [reload,setReload]    = useState(0);
    const [nivel,setNivel]      = useState(0);
    const [load,setLoad]        = useState(0);
    const [sendD,setSendD] = useState({
        valor:'',
        forma:'',
    })
    useEffect(()=>{ },[load,nivel])
    const titulos = [ 
        {nome:'DADOS',      w:8,   campo:'nome'},  
        {nome:'DEPÓSITO',   w:4,    campo:'valor'}, 
        {nome:'INDICADOS',  w:3,    campo:'indicados'},  
        {nome:'DATA',       w:5,    campo:'criacao'} 
    ]; 
    let dados = null;  
    useEffect(()=>{    
        var interV = setInterval(()=>{
            try { 
                if(reload!=GM.makerEsportes.up){ 
                    setReload(GM.makerEsportes.up); 
                    setAlerta({close:true})
                }  
            } catch (error) { } 
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);  
    if(typeof GM.makerEsportes !== 'undefined'){
        dados   = []; 
        Object.values(GM.makerEsportes.dados).forEach((d)=>{
            const {id,status,tipo,valor,pago,criacao}=d;  
            dados.push({
                campos  :   d,
                id      :   <Text  noOfLines={'1'} py={'5px'} >{id}</Text>, 
                nome    :   <Text  noOfLines={'1'} py={'5px'} >{status}</Text>, 
                nome    :   <Text  noOfLines={'1'} py={'5px'} >{tipo}</Text>, 
                nome    :   <Text  noOfLines={'1'} py={'5px'} >{valor}</Text>, 
                nome    :   <Text  noOfLines={'1'} py={'5px'} >{pago}</Text>, 
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
                
            });
        })
    } 

    return (
        <Stack gap={'20px'}>
            <SimpleGrid    columns={{base:2,'lg':4}} spacing={'20px'}>  
                <CarBoxInfo titulo='Indicados'      sub='Diretos'       valor={0} icon={FaDollarSign}/>
                <CarBoxInfo titulo='Indicados'      sub='Indiretos'     valor={0} icon={FaCheckDouble}/>
                <CarBoxInfo titulo='Ganhos'         sub='Indicação'     valor={0} icon={FaCheck}/>
                <CarBoxInfo titulo='Ganhos'         sub='Residual'      valor={0} icon={FaHistory}/>
            </SimpleGrid> 
            <CardBox w={{base:'100%'}}>
                <CardBoxTitulo mb='10px'>Seleção de Nível</CardBoxTitulo> 
                <BoxLevel nivel={nivel} setNivel={setNivel} /> 
            </CardBox>  
            <CardBox w={{base:'100%'}}> 
                <CardBoxTitulo position='absolute'>Indicados Nível {nivel+1}</CardBoxTitulo> 
                <ListaResponsiva titulos={titulos} dados={dados} max={5}  /> 
            </CardBox> 
        </Stack>
    )
};
export default PageRede;