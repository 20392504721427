import { io }   from "socket.io-client"; 
import md5 from 'md5'; 

export let web = {
    session     :   md5('cliPainel'),
    server      :   "openfourtrading.com:5010",
    socket      :   null,
    conectado   :   false,
    token       :   null 

}
export let user = null; 
export let GM = {};
export let srvTime = 0; 
export let saldos = {};
export const setSession = function(send){ 
    localStorage.setItem(web.session,send.token); 
    web.token       =   send.token; 
    user            =   send.dados;  
} 
export const conectarServer = function(callback){ 
    if(!web.conectado){
        web.token     =   localStorage.getItem(web.session);  
        if(typeof web.token == 'undefined'|| web.token == '' || web.token == null){
            user = {};
        } 
        web.socket    =  new io("wss://"+web.server,{
        // web.socket    =  new io("ws://localhost:5010",{
            withCredentials: true, 
            query: {
                session: web.token
            }
        });
        let interV = null; 
        web.socket.on("connect", (socket) => {    
            try {  if(web.token!==''){ web.socket.emit('dadosGeral');} } catch (error) { } 
            web.conectado =true;
            clearInterval(interV);
            var interV =  setInterval(()=>{  
                try {  web.socket.emit('autologin',web.token);  } catch (error) { }
                try {  if(web.token!==''){ web.socket.emit('dadosGeral');} } catch (error) { } 
             },1000) 
        });  
        web.socket.on("timeServer", (time) => {  
            srvTime = time;
        });
        web.socket.on("autologin", (d) => {   
            setSession(d)
        });
        web.socket.on("dadosGeral", (d) => {    
            Object.keys(d).forEach((tab)=>{
                if(tab == 'saldos'){ 
                    try { saldos = d[tab][0] } catch (error) { saldos = {}; } 
                }else{
                    if(typeof GM[tab]  == 'undefined'){
                        GM[tab]  = {
                            up      :   0,
                            dados   :   {}
                        };
                    } 
                    d[tab].forEach((dd)=>{ 
                        GM[tab].dados[dd.id] = dd 
                    }) 
                    GM[tab].up = new Date().getTime();
                }
               
            })  
        }); 
        
        web.socket.on("dadosGeralRemover", (d) => {   
            Object.keys(d).forEach((tab)=>{
                if(typeof GM[tab]  !== 'undefined'){ 
                    d[tab].forEach((id)=>{  
                       delete GM[tab].dados[id];
                    })
                } 
                GM[tab].up = new Date().getTime();
            })   
        }); 
        web.socket.on("disconnect", () => {  
            clearInterval(interV);
            GM={};
            console.log('desconectado')
            web.conectado =false;
        }); 
    }
    
}
export const sendForm=(props)=>{
    const {form,modulo,cmd,callback}= props;
    web.socket.removeAllListeners('sendForm').on("sendForm", (d) => {  
        web.socket.removeAllListeners('sendForm');
       if(d.modulo ==props.modulo && d.cmd ==props.cmd){
            try {
                callback(d.result)
            } catch (error) {
                
            }
       }
    });
    web.socket.emit('sendForm',{form,modulo,cmd}); 
}
export const desconectarServer = async function(){ 
    if(web.socket != null){ 
        web.socket.close();
        web.socket =null;
        web.conectado = false;  
    }
} 
