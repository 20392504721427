import PageDashboard from "./PageDashboard"
import PageDeposito from "./PageDeposito"
import PageFinanceiro from "./PageFinanceiro"
import PagePacotes from "./PagePacotes"
import PageRede from "./PageRede"
import PageSaque from "./PageSaque"
import LoginPainel from "./loginPainel"

export default {
    LoginPainel,
    PageDashboard,
    PageFinanceiro,
    PageDeposito,
    PagePacotes,
    PageSaque,
    PageRede
    
}