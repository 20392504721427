import { Box, Button, Center, Flex, FormControl, FormLabel, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, Textarea, Tooltip, useSteps, useToast } from "@chakra-ui/react";
import CarBoxInfo from "../../components/CardBox/CarBoxInfo";
import { FaCheck, FaCheckDouble, FaCode, FaCopy, FaDollarSign, FaDollyFlatbed, FaHistory, FaListAlt, FaMoneyBill, FaQrcode, FaRegTimesCircle, FaReplyAll, FaRocket, FaRocketchat, FaTimes, FaUser, FaWallet } from "react-icons/fa";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../components/CardBox/CardBox";
import { GM, sendForm,saldos } from "../../conn/WebPainel";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../components/ListaResponsiva/ListaResponsiva";
import { SiTether } from "react-icons/si";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { GiTheater } from "react-icons/gi";
import QRCode from "react-qr-code";
import { cfg } from "../../dados/dados";
import InputCampo from "../../components/Input/modelos/InputCampo";
import { FaPix } from "react-icons/fa6";
const BoxDeposito =function (props) {
    const [alerta,setAlerta] = useState({});   
    const toast = useToast()
    let {sendD={},setSendD=()=>{},setLoad={}} = props;  
    let [cpf,setCpf] = useState(undefined);
    let pedido = null; 
    if(typeof GM.deposito !== 'undefined'){
        pedido = Object.values(GM.deposito.dados); 
    }  
    useEffect(()=>{},[alerta]); 
    let ViewBox = null; 
    console.log(pedido);
    if(pedido == null){

    }else if(pedido.length>0){
        var d = pedido[0]; 
        switch (d.codtip) {
            case 1:
                ViewBox = <>
                    <CardBoxSub textAlign='center' w='100%'  >Pagamento via Crypto</CardBoxSub> 
                    <Stack spacing={0} pt='10' align='center' justify='center' gap={'20px'}>  
                        <Box bg='tema1.100' w={{base:'100%'}}   borderRadius={'lg'}>
                            <Center p='20px' bg={'tema.cor002'}>
                                <QRCode value={d.carteira}  bgColor="transparent" size={240}  /> 
                            </Center> 
                        </Box>
                        <Stack align='center' justify={'center'} flexDir={'row'}  w={{base:'100%'}}> 
                            <Text  color='tema.cor002'>
                                Escanei a carteira BEP-20 via QRCODE ou via copiar e colar!
                            </Text>   
                        </Stack>
                        <Stack align='center' justify={'center'} flexDir={'row'} w={{base:'100%'}} gap='20px'>
                            <FormControl w='full'>
                                <FormLabel
                                    color='white' w='full'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Valor
                                </FormLabel>
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                {d.valor} USDT
                                </Text>
                            </FormControl>  
                            <FormControl>
                                <FormLabel
                                    color='white'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Expira Em:
                                </FormLabel> 
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                    {(d.expira!== null) ? new Date(d.expira).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}) : "Não definido"}
                                </Text>
                            </FormControl>
                        </Stack>
                        <Stack align='center' w={'100%'} justify={'center'} flexDir={'row'}  spacing={1}>  
                             
                            <Textarea id="copyText" color={'tema.cor002'} overflow='hidden' bg='tema.bg001'   border='0.5px solid' borderColor='tema.cor002' isReadOnly placeholder='Here is a sample placeholder' w={{base:'100%'}} resize='none' onClick={(e)=>{ 
                                e.preventDefault();   
                                var Url = document.getElementById("copyText");
                                Url.select() 
                                document.execCommand("copy");
                                toast({
                                    title: 'Cópia realizada com sucesso!',
                                    description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                    status: 'success',
                                    position:'top-right',
                                    duration: 9000,
                                    isClosable: true,
                                })      
                            }}
                            defaultValue={d.carteira}
                            /> 
                        </Stack>  
                        <Button onClick={(e)=>{
                             e.preventDefault();   
                             var Url = document.getElementById("copyText");
                             Url.select() 
                             document.execCommand("copy");
                             toast({
                                 title: 'Cópia realizada com sucesso!',
                                 description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                 status: 'success',
                                 position:'top-right',
                                 duration: 9000,
                                 isClosable: true,
                             })      
                        }} leftIcon={<FaCopy/>} variant={'outline'} colorScheme="cyan">Copiar Carteira</Button>
                        <Button onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Atenção',
                                    size:'md',
                                    mensagem:'Caso já tenha pago, o seu pacote não será ativado, deseja realmente cancelar esse pagamento?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'cyan',
                                            cmd:()=>{
                                                sendForm({modulo:'pedidos',cmd:'deletar',form:{id:d.id},callback:(result)=>{
                                                    if(result.erro){
                                                        result.fechar =true;
                                                        result.size = 'md';
                                                        setAlerta(result);
                                                    }else{ 
                                                        setAlerta({close:true});
                                                    }
                                                    
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} leftIcon={<FaTimes/>} variant={'ghost'} colorScheme="red">Cancelar Pagamento</Button>
                    </Stack> 
                </>;
            break; 
            case 2:
                console.log(d)
                ViewBox = <>
                    <CardBoxSub textAlign='center' w='100%'  >Pagamento via PIX</CardBoxSub> 
                    <Stack spacing={0} pt='10' align='center' justify='center' gap={'20px'}>  
                        {/* 
                        
                        
                        
                         */}
                         <Box bg='tema1.100' w={{base:'100%'}}   borderRadius={'lg'}>
                            <Center p='20px' bg={'tema.cor002'}>
                                {d.carteira!==null?<QRCode value={d.carteira}  bgColor="transparent" size={240}  />:''}
                                 
                            </Center> 
                        </Box>
                         <Stack align='center' justify={'center'} flexDir={'row'}  w={{base:'100%'}}> 
                            <Text  color='tema.cor002'>
                                Escanei a QRCODE ou  copie e cole o PIX!
                            </Text>   
                         </Stack>
                         <Stack align='center' justify={'center'} flexDir={'row'} w={{base:'100%'}} gap='20px'>
                            <FormControl w='full'>
                                <FormLabel
                                    color='white' w='full'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Valor
                                </FormLabel>
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                {d.valor*d.quantidade } BRL
                                </Text>
                            </FormControl>  
                            <FormControl>
                                <FormLabel
                                    color='white'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Expira Em:
                                </FormLabel> 
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                    {(d.expira!== null) ? new Date(d.expira).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}) : "Não definido"}
                                </Text>
                            </FormControl>
                        </Stack>
                         <Stack align='center' w={'100%'} justify={'center'} flexDir={'row'}  spacing={1}>  
                             
                             <Textarea id="copyText" color={'tema.cor002'} overflow='hidden' bg='tema.bg001'   border='0.5px solid' borderColor='tema.cor002' isReadOnly placeholder='Here is a sample placeholder' w={{base:'100%'}} resize='none' onClick={(e)=>{ 
                                 e.preventDefault();   
                                 var Url = document.getElementById("copyText");
                                 Url.select() 
                                 document.execCommand("copy");
                                 toast({
                                     title: 'Cópia realizada com sucesso!',
                                     description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                     status: 'success',
                                     position:'top-right',
                                     duration: 9000,
                                     isClosable: true,
                                 })      
                             }}
                             defaultValue={d.carteira}
                             /> 
                         </Stack>  
                         <Button onClick={(e)=>{
                              e.preventDefault();   
                              var Url = document.getElementById("copyText");
                              Url.select() 
                              document.execCommand("copy");
                              toast({
                                  title: 'Cópia realizada com sucesso!',
                                  description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                  status: 'success',
                                  position:'top-right',
                                  duration: 9000,
                                  isClosable: true,
                              })      
                         }} leftIcon={<FaCopy/>} variant={'outline'} colorScheme="cyan">Copiar Carteira</Button>
                         <Button onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Atenção',
                                    size:'md',
                                    mensagem:'Caso já tenha pago, o seu pacote não será ativado, deseja realmente cancelar esse pagamento?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'cyan',
                                            cmd:()=>{
                                                sendForm({modulo:'pedidos',cmd:'deletar',form:{id:d.id},callback:(result)=>{
                                                    if(result.erro){
                                                        result.fechar =true;
                                                        result.size = 'md';
                                                        setAlerta(result);
                                                    }else{ 
                                                        setAlerta({close:true});
                                                    }
                                                    
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} leftIcon={<FaTimes/>} variant={'ghost'} colorScheme="red">Cancelar Pagamento</Button>
                    </Stack> 
                </>;
            break;
            default:
                break;
        }
        
    }else{ 
        ViewBox = <>
            <CardBoxTitulo textAlign='center' w='100%' ><Icon as={FaMoneyBill} fontSize={'18px'} mr={'10px'}/>Deposito</CardBoxTitulo>
                <Stack gap={'20px'}> /
                    <SliderThumbWithTooltip  sendD={sendD} setSendD={setSendD} />  
                    {sendD.forma==2?  
                    <InputCampo
                        icone={ FaPix}
                        tipo='cpf'
                        onChange={(el)=>{
                            setCpf(el.target.value);
                        }}
                        titulo='CPF'
                        name='cpf'
                        placeholder="000.000.000-00"
                        error={''}
                    />
                    :
                    <InputCampo
                        icone={ FaWallet} 
                        onChange={(el)=>{
                            setCpf(el.target.value);
                        }}
                        titulo='Carteira BEP-20'
                        name='cpf'
                        placeholder="BEP-20"
                        error={''}
                    />}
                    <Box  >
                        <Text color={'tema.cor002'} mb={'10px'}>Forma de Recebimento</Text>
                        <Flex w={'100%'} gap={'20px'}>
                            <Button  leftIcon={<SiTether/>} w={'50%'} colorScheme={sendD.forma==1?"cyan":'whiteAlpha'} variant={sendD.forma==1?"solid":'outline'} onClick={()=>{
                                if(sendD.forma==1){
                                    sendD.forma =0;
                                    setSendD(sendD);
                                }else{
                                    sendD.forma =1;
                                    setSendD(sendD);
                                }
                                setLoad({});
                            }}>
                                USDT
                            </Button>
                            <Button leftIcon={<FaQrcode/>} w={'50%'} colorScheme={sendD.forma==2?"cyan":'whiteAlpha'}   variant={sendD.forma==2?"solid":'outline'} onClick={()=>{
                                if(sendD.forma==2){
                                    sendD.forma =0;
                                    setSendD(sendD);
                                }else{
                                    sendD.forma =2;
                                    setSendD(sendD);
                                }
                                setLoad({});
                            }}>
                                PIX
                            </Button>
                        </Flex>
                    
                    </Box>
                    <Button onClick={(e)=>{
                        e.preventDefault();   
                        setAlerta({load:true})   
                        sendForm({ modulo:'pedidos',cmd:'deposito',form:(sendD.forma==2?{forma:sendD.forma,valor:sendD.valor,cpf}:''),callback:(result)=>{  
                            if(result.erro){
                                result.fechar =true;
                                result.size = 'md';  
                                setAlerta(result);
                            }else{  
                                setAlerta({close:true});  
                            }
                        }}); 
                    }} mt={'10px'} leftIcon={<FaMoneyBill/>} w={'100%'} colorScheme="cyan" >
                        Fazer Saque
                    </Button>
                </Stack>
            </>
    }
    
  
    return (
       <Stack>
            {ViewBox}
            <BoxWindow param ={alerta} />
       </Stack>
    )
} 
const SliderThumbWithTooltip =function (props) {
    let {sendD={},setSendD=()=>{},setLoad={}} = props;  
    const [sliderValue, setSliderValue] = useState(0)
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(()=>{
        sendD.valor = sliderValue
        setSendD(sendD);
    },[sliderValue])
    return (
      <Box> 
        <Text color={'tema.cor002'} mb={'10px'}>Valor de Saque</Text>
        <NumberInput id='ipdeposito'  step={10}   value={`$` +sliderValue} min={10} onChange={(v)=>{
            var num = parseInt(v);
            if(num>props.max){
                num=props.max;
            } 
            if(num%10){
                num=num-((num%10))
            } 

            setSliderValue(num);  
        }} max={100000} variant={'outline'} color='tema.cor001' colorScheme="cyan">
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper  color="tema.cor002"/>
                <NumberDecrementStepper  color="tema.cor002"/>
            </NumberInputStepper>
        </NumberInput>
        <Slider
            focusThumbOnChange = {false}
            value={sliderValue}
            min={10}
            max={saldos.saldo} 
            step={10}
            colorScheme='cyan'
            onChange={(v) => setSliderValue(v)} 
            mt={'5px'}
            w={'95%'} 
        >
            <SliderMark color={'tema.cor002'} value={25000} mt='1' ml='-2.5' fontSize='sm'>
            25K
            </SliderMark>
            <SliderMark color={'tema.cor002'}  value={50000} mt='1' ml='-2.5' fontSize='sm'>
            50K
            </SliderMark>
            <SliderMark color={'tema.cor002'}  value={75000} mt='1' ml='-2.5' fontSize='sm'>
            75K
            </SliderMark>
            <SliderTrack>
            <SliderFilledTrack />
            </SliderTrack> 
            <SliderThumb /> 
        </Slider> 
      </Box>
    )
}
const PageSaque = function(){
    const [load,setLoad] = useState(0);
    const [sendD,setSendD] = useState({
        valor:'',
        forma:'',
    })
    useEffect(()=>{ },[load]) 
    const [reload,setReload]= useState(0);
    const titulos = [  
        {nome:'STATUS',     w:10,    campo:'nome'},  
        {nome:'VALOR',      w:5,    campo:'valor'}, 
        {nome:'DATA',       w:5,    campo:'criacao'} 
    ]; 
    let dados = null; 
    useEffect(()=>{    
        var interV = setInterval(()=>{
            try { 
                if(reload!=(GM.pedidos.up + GM.deposito.up)){ 
                    setReload((GM.pedidos.up + GM.deposito.up)); 
                } 
                
            } catch (error) { }  
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);
    
    let depositoP = 0; 
    try { depositoP = Object.values(GM.deposito.dados)[0].valor_base;} catch (error) {depositoP = 0;}  
    const situaInfo = {
        "P": 'A Pagar ',
        "N": 'Pago ',
        "E": 'Expirado'
    }
    if(typeof GM.pedidos !== 'undefined'){
        dados   = []; 
        Object.values(GM.saque.dados).forEach((d)=>{
            const {id,situa,codtip,valor,quantidade,moeda,criacao}=d;  
            dados.push({
                campos  :   d,
                id      :   <Text  noOfLines={'1'}>{id}</Text>, 
                nome    :   <Text  noOfLines={'1'}>{situaInfo[situa]} via {codtip} {codtip==1?'CRYPTO':'PIX'}</Text>,  
                valor    :   <Text  noOfLines={'1'}>{valor*quantidade} {moeda}</Text>,  
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
            });
        })
    }   
    return (
        <Stack gap={'20px'}>
            <SimpleGrid    columns={{base:2,'lg':4}} spacing={'20px'}>
                <CarBoxInfo titulo='Saldo' valor={saldos.saldo} icon={FaDollarSign}/>
                <CarBoxInfo titulo='Depósitos'  sub='Totais'    valor={(saldos.depositos+depositoP)} icon={FaCheckDouble}/>
                <CarBoxInfo titulo='Depósitos'  sub='Completo'  valor={saldos.depositos} icon={FaCheck}/>
                <CarBoxInfo titulo='Depósitos'  sub='Pendente' valor={depositoP} icon={FaHistory}/>
            </SimpleGrid> 
            <Flex gap={'20px'} direction={{base:'column',lg:'row'}}>
                <CardBox w={{base:'100%',lg:'320px'}}>
                    <BoxDeposito  sendD={sendD} setSendD={setSendD} setLoad={setLoad}/>
                </CardBox>
                <CardBox w={{base:'100%'}}>
                    <CardBoxTitulo position='absolute'>Últimos Saques</CardBoxTitulo>
                    <ListaResponsiva titulos={titulos} dados={dados} max={5}  />
                </CardBox>
            </Flex>
        </Stack>
    )
};
export default PageSaque;