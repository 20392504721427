import Site from "./modelos/Site";
import Painel from "./modelos/Painel";  
import 'animate.css'; 
const Layout=(props)=>{ 
    var page = null 
    switch (props.lay.modelo) {   
        case 'painel':
            page= <Painel content={props.children} page={props.page}/>
        break;
        default:
            page= <Painel content={props.children} page={props.page}/>
        break;
        
    } 
    return page;
}
export default Layout;