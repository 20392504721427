import { Box, Flex, Img } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {Helmet} from 'react-helmet'   
import { cfg } from "../../dados/dados"; 
import {user,conectarServer,desconectarServer} from "../../conn/WebPainel";
// pages
import Pages from "../../pages/Painel";
import { Navigate } from "react-router-dom"; 
import MenuMaker from "./Painel/MenuPainel";

export const PagePainel = [ 
    {  nome  :   "Login",    slug   :   "login"          ,page: 'LoginPainel',        login: false, url: '/painel/dashboard'},
    {  nome  :   "Dashboard",   links: [{n:'Bem vindo!'}], 
        slug   :   "dashboard", page: 'PageDashboard',    login: true,  url: false},
    {  nome  :   "Depósito",    links: [{n:'Dashboard',l:'/painel/dashboard'},{n:'Depósito'}], 
        slug   :   "deposit",   page: 'PageDeposito',        login: true,  url: false
    },
    {  nome  :   "Financeiro",    links: [{n:'Dashboard',l:'/painel/dashboard'},{n:'Financeiro'}], 
        slug   :   "finance",   page: 'PageFinanceiro',        login: true,  url: false
    }, 
    {  nome  :   "Saque",    links: [{n:'Dashboard',l:'/painel/dashboard'},{n:'Saque'}], 
        slug   :   "withdraw",   page: 'PageSaque',        login: true,  url: false
    },
    {  nome  :   "Rede",    links: [{n:'Dashboard',l:'/painel/dashboard'},{n:'Rede'}], 
        slug   :   "network",   page: 'PageRede',        login: true,  url: false
    },
    {  nome  :   "Pacotes",    links: [{n:'Dashboard',l:'/painel/dashboard'},{n:'Pacotes'}], 
        slug   :   "package",   page: 'PagePacotes',        login: true,  url: false
    },
    // {  nome  :   "Esportes",    links: [{n:'Dashboard',l:'/painel/dashboard'}], slug   :   "makeraovivo"    ,page: 'MakerAoVivo',       login: true,  url: false},
    // // operador
    // {  nome  :   "Cadastro de Usuários",  links: [{n:'Usuários',l:'/painel/usuario/lista'},   {n:'Cadastro'}] ,  slug   :   "usuario/cadastro"     ,page: 'CadastroUsuario',       login: true,  url: false},
    // {  nome  :   "Lista de Usuários",   links: [{n:'Usuários'}]                                              ,  slug   :   "usuario/lista"        ,page: 'ListaUsuarios',       login: true,  url: false},
    // {  nome  :   "Acesso de Usuários",  links: [{n:'Usuários',l:'/painel/usuario/lista'},   {n:'Acesso'}]     ,  slug   :   "usuario/acesso"       ,page: 'CadastroUsuario',       login: true,  url: false},
    // // competicoes
    // {  nome  :   "Cadastro de Competição",  links: [{n:'Competição',l:'/painel/competicao/lista'},   {n:'Cadastro'}] ,  slug   :   "competicao/cadastro"     ,page: 'CadastroCompeticao',       login: true,  url: false},
    // {  nome  :   "Lista de Competição",   links: [{n:'Competição'}]                                                 ,  slug   :   "competicao/lista"        ,page: 'ListaCompeticao',       login: true,  url: false},
    // {  nome  :   "Região de Competição",  links: [{n:'Competição',l:'/painel/competicao/lista'},   {n:'Região'}]     ,  slug   :   "competicao/regiao"       ,page: 'ListaCompeticao',       login: true,  url: false},
    
    // //partidas 
    // {  nome  :   "Cadastro de Partidas",  links: [{n:'Partidos',l:'/painel/partidas/lista'},   {n:'Cadastro'}] ,  slug   :   "partidas/cadastro"    ,page: 'CadastroEsporte',     login: true,  url: false},
    // {  nome  :   "Lista de Partidas",     links: [{n:'Partidos'}]                                             ,  slug   :   "partidas/lista"       ,page: 'ListaEsportes',       login: true,  url: false},
        
    // // jogadores 
    // {  nome  :   "Cadastro de Jogador",  links: [{n:'Jogadores',l:'/painel/jogador/lista'},   {n:'Cadastro'}] ,  slug   :   "jogador/cadastro"    ,page: 'CadastroJogador',     login: true,  url: false},
    // {  nome  :   "Lista de Jogador",     links: [{n:'Jogadores'}]                                            ,  slug   :   "jogador/lista"       ,page: 'ListaJogador',       login: true,  url: false},


    // // esportes 
    // {  nome  :   "Cadastro de Esporte",  links: [{n:'Esportes',l:'/painel/esportes/lista'},   {n:'Cadastro'}] ,  slug   :   "esportes/cadastro"    ,page: 'CadastroEsporte',     login: true,  url: false},
    // {  nome  :   "Lista de Esportes",    links: [{n:'Esportes'}]                                             ,  slug   :   "esportes/lista"       ,page: 'ListaEsportes',       login: true,  url: false},
];
const LayoutPainel=(props)=>{ 
    const base =useRef();
    const [web,setWeb] = useState(null); 
    const [reload,setReload] = useState(null); 
    var pag         =   null;
    var conteudo    =   null;
    PagePainel.forEach((p)=>{ 
        if(props.page[1] == p.slug.toUpperCase()){
            pag =p;
        }else if(props.page[1]+'/'+props.page[2] == p.slug.toUpperCase()){
             pag =p; 
        }
    })  
    if(web == null){
        conectarServer()
    }  
    useEffect(()=>{ },[user]);  
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            if(user!==null && reload!==user.dataUP){ 
                setReload(user.dataUP);
            } 
        },1000) 
        return ()=>{ 
            clearInterval(interV);
            desconectarServer();
        }
    },[])   
    if(pag == null){
        conteudo= <Navigate to='/painel/login' />;
    }else if(user==null){
        conteudo= <Box position='relative' w={'100vw'} h='100vh' bg={'tema.bg001'}>
            <div className="bgVideo" style={{zIndex:1}} > 
                <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
                    <video playsinline loop autoPlay muted autobuffer>
                    <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
                    Your browser does not support the video tag. I suggest you upgrade your browser.
                </video>` }}/>   
            </div> 
        </Box>
    }else if(typeof user.usuario =='undefined'){   
        if(pag.login){ 
            pag = PagePainel[0];
        }   
        const ViewPage = Pages[pag.page];
        conteudo= (
            <Box position='relative'>

                <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                    <div className="bgVideo" style={{zIndex:1}} > 
                        <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
                            <video playsinline loop autoPlay muted autobuffer>
                            <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
                            Your browser does not support the video tag. I suggest you upgrade your browser.
                        </video>` }}/>   
                    </div> 
                </Box>
                <Flex zIndex={'2'} position={'relative'} ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} minH={'100vh'} overflowX={'hidden'} overflowY={'auto'}>
                    <ViewPage/>
                </Flex>
            </Box>
        )  
    }else{
        if(!pag.login){
            pag = PagePainel[0];
            conteudo= <Navigate to={pag.url} />;
        }else{   
            const ViewPage = Pages[pag.page];
            conteudo= (
                <Flex position={'relative'}  w='100vw' h='100vh' overflowX={'hidden'} overflowY={'auto'}>
                    <Flex position='relative' bg={'tema.bg001'} maxW={'1280px'} m={'0 auto'}> 
                        <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                            <div className="bgVideo" style={{zIndex:1}} > 
                                <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
                                    <video playsinline loop autoPlay muted autobuffer>
                                    <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
                                    Your browser does not support the video tag. I suggest you upgrade your browser.
                                </video>` }}/>  
                                <div className='mask'></div>
                            </div> 
                        </Box>
                        <Flex zIndex={'2'}    position={'relative'}  ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} w={'100vw'} h={'auto'}  >
                            <MenuMaker pag={pag} page ={props.page}/>
                            <Flex position={'relative'} pl={{base:'0',md:'285px'}} pr={'15px'} top={{base: '60px' , md:'90px'}}  direction={'column'}   w='100%' h={'auto'}   >
                                <ViewPage/> 
                            </Flex> 
                        </Flex>
                    </Flex>
                </Flex>
                 
            )
        }
        
    }
    return <>
        <Helmet> 
            {/* <title>O4T - {pag.nome}</title>
            <meta name="title" content={cfg.nome} />
            <meta name="description" content={cfg.descricao} />

            <link rel="icon" href={'/assets/'+cfg.site+'/icon.ico'} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={"https://"+cfg.site} />
            <meta property="og:title" content={"https://"+cfg.nome} />
            <meta property="og:description" content={cfg.descricao} />
            <meta property="og:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={"https://"+cfg.site} />
            <meta property="twitter:title" content={cfg.site} />
            <meta property="twitter:description" content={cfg.descricao} />
            <meta property="twitter:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} /> */}
        </Helmet>  
        {conteudo}
    </>
    
}
export default  LayoutPainel;