import { Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { hexToRGB, parseValor } from "../../Function";
import { cfg } from "../../dados/dados";

const CarBoxInfo = function(props){
    const {children ,window=false,icon= null,titulo='',moeda=true,sub='',subC='tema.cor001', ...rest} =props 
    const valor =typeof props.valor=='undefined'?0:props.valor;
    var bg = (window)?'transparent':'linear-gradient(178.68deg, rgba('+hexToRGB(cfg.theme.cores.bg002)+', 0.8) 50.88%, rgba(26, 31, 55, 0.4) 100%)'; 
    return (
        <Flex w={'100%'} direction={'column'}  bg={bg} p={'15px'} borderRadius={'lg'} {...rest}  justify={'space-between'}>
            <Flex w={'100%'}   direction={'row'}  justify={'space-between'}>
                <Stack gap={'0px'}>
                    <Text color={'tema.cor001'} lineHeight={'14px'} fontSize={{base:'12px',md:'14px','xl':'16px'}}>{titulo}</Text> 
                    <Flex color={'tema.cor002'} align={'baseline'} justify={'flex-start'} pt='0'>
                        {moeda?
                            <>
                                <Text fontSize={{base:'8px',md:'10px','xl':'12px'}} mr={'5px'}>$ </Text><Text fontSize={{base:'14px',md:'20px','xl':'24px'}}>{parseValor(valor,3).p}</Text> ,<Text fontSize={{base:'8px',md:'10px','xl':'12px'}}>{parseValor(valor,3).d}</Text>
                            </>:
                            <Text fontSize={{base:'14px',md:'20px','xl':'24px'}}>{valor}</Text> 
                        } 
                    </Flex> 
                </Stack>
                {icon!=null?<Icon as={icon} h={{base:'30px',md:'40px','xl':'48px'}} w={{base:'30px',md:'40px','xl':'48px'}} borderRadius={'lg'} p={{base:'8px',md:'10px','xl':'12px'}} bg={'tema.cor003'} color={'tema.cor002'}/>:null}
                
            </Flex>
            <Text color={subC} lineHeight={'14px'} textAlign={'left'} w='100%' pt={{base:'5px' ,'xl':'10px'}} fontSize={{base:'10px',md:'12px','xl':'14px'}}>{sub}</Text> 
        </Flex>
    )
}
export default CarBoxInfo;